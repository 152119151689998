import {
  Action,
  configureStore,
  ThunkAction,
  combineReducers,
} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createFieldsIdsReduce } from "./slice/createField-ids-slice";
import { geoJsonReduce } from "./slice/geoJson-slice";
import { createProducerIdReduce } from "./slice/ctreate-producer-id";
import { createFarmIdReduce } from "./slice/create-farm-id";
import { saveGroupIdReduce } from "./slice/seve-id-group";
import { createFieldIdReduce } from "./slice/create-lender-field-slice";
import { fileReduce } from "./slice/create-file-slice";
import { coordinateReduce } from "./slice/create-coordinate-button";
import { errorFieldReduce } from "./slice/create-field-error";

export const rootReducer = combineReducers({
  createFieldsIds: createFieldsIdsReduce,
  createGeoJson: geoJsonReduce,
  createPoducerId: createProducerIdReduce,
  createFarmId: createFarmIdReduce,
  saveGroupId: saveGroupIdReduce,
  createFieldId: createFieldIdReduce,
  createFile: fileReduce,
  createCoordinate: coordinateReduce,
  createErrorField: errorFieldReduce,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
const initStore = () => {
  return store;
};

export type AppStore = ReturnType<typeof initStore>;
export type AppState = ReturnType<AppStore["getState"]>;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
