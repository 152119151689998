const baseApi = process.env.NEXT_PUBLIC_BASE_API;

export const isUser = async (email: any, did: any) => {
  const data = { email: email };
  const response = fetch(`${baseApi}/validate_users`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: did,
    },
    body: JSON.stringify(data),
  }).then(async (res) => {
    if (res.ok) {
      const responsePost = await res.json();
      return responsePost.data;
    } else {
      return false;
    }
  });
  return response;
};
