import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

type coordinateProps = {
  pending?: boolean;
  error?: boolean;
  coordinateButton: any[];
};

const initialState: coordinateProps = {
  pending: false,
  error: false,
  coordinateButton: [],
};

export const coordinateSlice = createSlice({
  name: "createCoordinate",
  initialState,
  reducers: {
    coordinate: (state, action) => {
      state.coordinateButton = action.payload;
    },
  },
});
export const { coordinate } = coordinateSlice.actions;
export const selectCoordinate = (state: RootState) => state.createCoordinate;
export const coordinateReduce = coordinateSlice.reducer;
