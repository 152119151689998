export const getStorageValue = (
  key: any,
  defaultValue: any,
  parseJson: boolean = true
) => {
  if (typeof window !== "undefined") {
    const saved = localStorage.getItem(key);
    if (saved !== null) {
      // Si parseJson es verdadero, parsear como JSON. De lo contrario, devolver como cadena.
      return parseJson ? JSON.parse(saved) : saved;
    }
    return defaultValue;
  }
  return defaultValue;
};
