import { auth } from "../util/firebase-config";

export async function refreshAuthToken() {
  const user = auth.currentUser;
  if (user) {
    try {
      return await user.getIdToken(true);
    } catch (error) {
      console.error("Unable to refresh auth token:", error);
    }
  }
}

export const apiRequest = async (
  url: string,
  method: string,
  body?: any,
  token?: any
) => {
  const refreshedToken = await refreshAuthToken();

  const fetchOptions: RequestInit = {
    method: method,
    headers: {
      Authorization: refreshedToken || token,
    },
  };

  if (method !== "GET" && body) {
    fetchOptions.body = JSON.stringify(body);
  }

  let response = await fetch(url, fetchOptions).then(async (res) => {
    if (res.ok) {
      const responseJson = await res.json();

      return method === "GET" ? responseJson.data : res.status;
    } else {
      console.log("error", [res.status, res.statusText]);
      return method === "GET" ? [] : res.status;
    }
  });
  return response;
};
