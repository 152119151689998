import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

type idFarmProps = {
  pending?: boolean;
  error?: boolean;
  idFarm: any[];
};

const initialState: idFarmProps = {
  pending: false,
  error: false,
  idFarm: [],
};

export const farmIdSlice = createSlice({
  name: "createFarmId",
  initialState,
  reducers: {
    farmId: (state, action) => {
      state.idFarm = action.payload;
    },
  },
});
export const { farmId } = farmIdSlice.actions;
export const selectFarmId = (state: RootState) => state.createFarmId;
export const createFarmIdReduce = farmIdSlice.reducer;
