import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

type idGroupSaveProps = {
  pending?: boolean;
  error?: boolean;
  group_id: number;
};

const initialState: idGroupSaveProps = {
  pending: false,
  error: false,
  group_id: 0,
};

export const saveGroupIdSlice = createSlice({
  name: "saveGroupId",
  initialState,
  reducers: {
    groupId: (state, action) => {
      state.group_id = action.payload;
    },
  },
});
export const { groupId } = saveGroupIdSlice.actions;
export const selectSeveGroupId = (state: RootState) => state.saveGroupId;
export const saveGroupIdReduce = saveGroupIdSlice.reducer;
