import CryptoJS from "crypto-js";

const encryptionKey = process.env.NEXT_PUBLIC_ENCRYPTION_KEY as string;

export const encryptData = (data: any) => {
  try {
    const stringifiedData = JSON.stringify(data);
    const encryptedData = CryptoJS.AES.encrypt(
      stringifiedData,
      encryptionKey
    ).toString();

    return encryptedData;
  } catch (error) {
    console.error("Error encrypting data:", error);
    return null;
  }
};

export const decryptData = (encryptedData: any) => {
  if (!encryptedData) return null;
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData).replace(/['"]/g, "");
  } catch (error) {
    console.error("Error decrypting data:", error);
    return null;
  }
};
