import styles from "../styles/Home.module.css";
import ResponsiveDrawer from "./drawer";
import { cloneElement } from "react";
import Head from "next/head";
import SignIn from "./auth/signin";
import { Container, Skeleton, Stack } from "@mui/material";
import Script from "next/script";
import { useRouter } from "next/router";
import { useAuth } from "../context/auth-context";

function noSignInRedirect(path: String) {
  const pages = ["createAccount", "oauth", "terminos"];
  return pages.some((page) => path.includes(page));
}

export default function Layout({ children }: any) {
  const { user, loading, email, registered } = useAuth() as any;
  const router = useRouter();
  const noSignIn = noSignInRedirect(router.pathname);

  // Mostrar esqueletos de carga mientras se verifica la autenticación

  if (loading) {
    return (
      <Container>
        <Stack spacing={3} sx={{ mt: "10px", mb: "10px" }}>
          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="rectangular" width={"100%"} height={400} />
        </Stack>
      </Container>
    );
  }

  // Si el usuario está autenticado, mostrar el contenido
  if (!loading && !!email && !!registered && !noSignIn) {
    return (
      <div>
        <ResponsiveDrawer />
        <div className={styles.container}>
          <div>{cloneElement(children, { user })}</div>
        </div>
      </div>
    );
  }

  // Si el usuario no está autenticado y la página no requiere inicio de sesión, mostrar el contenido
  if (noSignIn && !registered) {
    return <>{children}</>;
  }

  // De lo contrario, mostrar la pantalla de inicio de sesión

  return <SignIn />;
}
