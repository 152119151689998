import { auth } from "../util/firebase-config";
import { createContext, useState, useEffect, useContext } from "react";
import {
  createUserWithEmailAndPassword,
  deleteUser,
  GoogleAuthProvider,
  onAuthStateChanged,
  onIdTokenChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";

import Layout from "../components/layout";
import { getStorageValue } from "../lib/storage-value";
import { decryptData, encryptData } from "../lib/encrypt";
import { isUser } from "../lib/isUser";
import { useTranslation } from "react-i18next";
import { apiRequest } from "../api/apiRequests";
import { sendALert } from "../util/send-alert";


const baseApi = process.env.NEXT_PUBLIC_BASE_API;

const AuthContext = createContext({}) as any;
export const useAuth = () => useContext(AuthContext);


export const AuthProvider = ({ children }: any) => {
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(
    decryptData(getStorageValue("user", null, false))
  );
  const [email, setEmail] = useState(
    decryptData(getStorageValue("email", "", false))
  );
  const [token, setToken] = useState(
    decryptData(getStorageValue("did", "", false))
  );
  const [loading, setLoading] = useState(
    getStorageValue("loading", true, true)
  );
  const [loadingUser, setLoadingUser] = useState(true);
  const [error, setError] = useState("");
  const [errorRegister, setErrorRegister] = useState("");
  const [messageSuccess, setMessageSuccess] = useState("");
  const [registered, setRegistered] = useState(
    getStorageValue("registered", false, true)
  );
  const [accepteTerms, setAccepteTerms] = useState(false);
  const [userId, setUserId] = useState(null);
  const [loginGoogle, setLoginGoogle] = useState(false);
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [isOpenRegister, setIsOpenRegister] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setLoading(true);
        try {
          await handleUserAuthentication(user);
          storeEncryptedUser(user);
          setUser(user);
          setLoading(false);
          //setLoadingUser(false);
        } catch (error) {
          console.error("Error handling user authentication:", error);
          setLoading(true);
          //setLoadingUser(true);
        }
      } else {
        setLoading(false);
        //setLoadingUser(true);
      }
    });

    const unsubscribeFromIdToken = onIdTokenChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken(true);
        storeEncryptedToken(token);
        setToken(token);
      }
    });

    return () => {
      unsubscribe();
      unsubscribeFromIdToken();
    };
  }, []);

  const handleUserAuthentication = async (user: any) => {
    const key = user.email.includes("@fastfarm") ? "getall" : user.email;
    storeEncryptedEmail(key);
    setEmail(key);

    setLoading(false);
    localStorage.setItem("loading", JSON.stringify(false));
  };

  const storeEncryptedToken = (token: any) => {
    const encryptedToken = encryptData(JSON.stringify(token));
    if (encryptedToken) {
      localStorage.setItem("did", encryptedToken);
    } else {
      console.error("encryptedToken is null");
      // Handle the case where encryptedToken is null
      throw new Error("encryptedToken is null");
    }
  };
  const storeEncryptedUser = (user: any) => {
    const encryptedUser = encryptData(JSON.stringify(user));
    if (encryptedUser) {
      localStorage.setItem("user", encryptedUser);
    } else {
      console.error("encryptedUser is null");
      // Handle the case where encryptedToken is null
      throw new Error("encryptedUser is null");
    }
  };

  const storeEncryptedEmail = (email: any) => {
    const encryptedEmail = encryptData(JSON.stringify(email));
    if (encryptedEmail) {
      localStorage.setItem("email", encryptedEmail);
    } else {
      console.error("encryptedEmail is null");
      // Handle the case where encryptedEmail is null
      throw new Error("encryptedEmail is null");
    }
  };

  const login = async (email: any, password: any) => {
    const isAllowed = await isUser(email, null);
    if (isAllowed) {
      if (isAllowed[0].terms_accepted == 1) {
        return signInWithEmailAndPassword(auth, email, password)
          .then(async (res) => {
            const user = res.user;
            if (user) {
              localStorage.setItem("registered", JSON.stringify(true));
              setRegistered(true);
            }
          })
          .catch((error: any) => {
            setError(error.message);
          });
      }
      if (isAllowed[0].terms_accepted == 0) {
        setUserId(isAllowed[0].user_id);
        setAccepteTerms(true);
      }
    }
    if (isAllowed == false) {
      setError(t("Login.message.messageErrorAuth") || "");
      setTimeout(() => {
        setError("");
      }, 4000);
    }
  };

  const googleLogin = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      const user = result.user;

      const isAllowed = await isUser(user.email, token);
      // Si el usuario no existe en la BD
      if (!isAllowed) {
        await deleteUser(user);
        setError(t("Login.message.messageErrorAuth") || "");
        setTimeout(() => {
          setError("");
        }, 2000);
        localStorage.setItem("loading", JSON.stringify(true));
        setIsOpenRegister(false);
        setTimeout(() => {
          setIsOpenRegister(true);
        }, 2500);
        return;
      }

      // Si el usuario existe en la BD, procedemos a autenticar en Firebase
      await signInWithCredential(auth, credential!);

      // Si el usuario no ha aceptado los términos
      if (isAllowed[0].terms_accepted == 0) {
        setUserId(isAllowed[0].user_id);
        setAccepteTerms(true);
        setLoginGoogle(true);
        return;
      }

      // Si el usuario ha aceptado los términos
      if (isAllowed[0].terms_accepted == 1) {
        localStorage.setItem("registered", JSON.stringify(true));
        setRegistered(true);
      }
    } catch (error: any) {
      const errorMessage = error.message;
      setError(error.message);
      const email = error.customData.email;
      const credential = GoogleAuthProvider.credentialFromError(error);
    }
  };


  const register = (email: any, password: any, name: string, phone: any, userGuest: boolean) => {
    return createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user as any;
        if (!userGuest) {
          sendEmailVerification(user).then(() => {
            setIsBackdropOpen(true);
            setTimeout(() => {
              setIsBackdropOpen(false);
            }, 5000);
          }).catch((error) => {
            console.error("Error al enviar el correo de verificación:", error);
          });
        }
        const body = {
          email,
          name,
          telephone: phone,
          firebase_id: user.uid,
          terms_accepted: 1,
        };
        const responseCreateUser = apiRequest(
          `${baseApi}/create_user`,
          "POST",
          body,
          user.accessToken
        );
        (await responseCreateUser) == 200
          ? setMessageSuccess(t("Login.message.messageSuccesRegister") || "")
          : setErrorRegister('No se pude completar el registro, por favor comunicate a soporte de FastFarm'); await sendALert(
            user.uid,
            `No se creo el usario con id de firebase ${user.uid} en la BD de FastFarm`
          );
        setTimeout(() => {
          setMessageSuccess("");
        }, 3000);
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        setErrorRegister(error.message);
      });
  };

  const logout = () => {
    return signOut(auth);
  };
  const sendPasswordReset = (email: any) => {
    return sendPasswordResetEmail(auth, email);
  };

  const value = {
    // isAuthenticated,
    registered,
    user,
    login,
    register,
    googleLogin,
    logout,
    loading,
    token,
    email,
    sendPasswordReset,
    error,
    errorRegister,
    messageSuccess,
    accepteTerms,
    userId,
    loginGoogle,
    isBackdropOpen,
    isOpenRegister,
  } as any;
  return (
    <AuthContext.Provider value={value}>
      <Layout>{children}</Layout>
    </AuthContext.Provider>
  );
};
