export const sendALert = async (idUserFirebase: any, messageAlert: any) => {
  let data = { idUserFirebase, messageAlert };
  fetch("/api/alert", {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((res) => {
    if (res.status === 201) {
      console.log("Se envio la alerta");
    } else {
      console.log("No se envio la alerta");
    }
  });
};
