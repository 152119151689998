import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

export type idsState = {
  pending?: boolean;
  error?: boolean;
  ids: any[];
};

const initialState: idsState = {
  pending: false,
  error: false,
  ids: [],
};

export const fieldsIdSlice = createSlice({
  name: "createFieldsIds",
  initialState,
  reducers: {
    fieldsIds: (state, action) => {
      state.ids = action.payload;
    },
  },
});
export const { fieldsIds } = fieldsIdSlice.actions;
export const selectFieldsIds = (state: RootState) => state.createFieldsIds;
export const createFieldsIdsReduce = fieldsIdSlice.reducer;
