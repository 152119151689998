import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

export type createGeoJsonProp = {
  pending?: boolean;
  error?: boolean;
  geoJson: {
    type: string;
    features: any[];
  };
};

const initialState: createGeoJsonProp = {
  pending: false,
  error: false,
  geoJson: { type: "", features: [] },
};

export const geoJsonSlice = createSlice({
  name: "createGeoJson",
  initialState,
  reducers: {
    geoJsonInfo: (state, action) => {
      state.geoJson = action.payload;
    },
  },
});

export const { geoJsonInfo } = geoJsonSlice.actions;
export const selectGeoJson = (state: RootState) => state.createGeoJson;
export const geoJsonReduce = geoJsonSlice.reducer;
