import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

type idFieldProps = {
  pending?: boolean;
  error?: boolean;
  idField: any;
  type: any;
};

const initialState: idFieldProps = {
  pending: false,
  error: false,
  idField: null,
  type: null,
};

export const fieldIdSlice = createSlice({
  name: "createFieldId",
  initialState,
  reducers: {
    fieldId: (state, action) => {
      state.idField = action.payload.idField;
      state.type = action.payload.type;
    },
  },
});
export const { fieldId } = fieldIdSlice.actions;
export const selectFieldId = (state: RootState) => state.createFieldId;
export const createFieldIdReduce = fieldIdSlice.reducer;
