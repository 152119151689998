import React, { useState, useRef } from "react";
import { Box, Checkbox, FormControlLabel, Modal, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/auth-context";
import { useRouter } from "next/router";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "40%", sm: "40%", md: "40%", lg: "40%", xl: "20%" },
  heigth: { xs: "60%", sm: "60%", md: "60%", lg: "60%", xl: "30%" },
  bgcolor: "background.paper",
  border: "2px solid #FFF",
  boxShadow: 24,
  p: 3,
};
type registerProps = {
  open?: any;
  onClose?: any;
  email?: any;
  password?: any;
  userId?: any;
};

const baseApi = process.env.NEXT_PUBLIC_BASE_API;

export const TermsAccepte = ({
  open,
  onClose,
  email,
  password,
  userId,
}: registerProps) => {
  const router = useRouter();

  const { login, googleLogin, loginGoogle, registered } = useAuth() as any;
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      submitButtonRef.current?.click();
    }
  };
  const handleClose = () => onClose();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const data = { user_id: userId, terms_accepted: 1 };
    const response = fetch(`${baseApi}/update_terms`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
      },
      body: JSON.stringify(data),
    }).then(async (res) => {
      if (res.status == 200) {
        if (loginGoogle == false) {
          try {
            await login(email, password);
            router.push("/dashbord");
          } catch (error: any) {
            console.log("error login", error.message);
          }
        }
        if (loginGoogle) {
          try {
            await googleLogin();
            if (registered == true) {
              router.reload();
            }
          } catch (error: any) {
            console.log("error login", error.message);
          }
        }
      }
    });
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
    >
      <Box sx={style}>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignContent="center"
        >
          <form onSubmit={handleSubmit} noValidate>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  sx={{
                    color: "#005f40",
                    fontSize: "1rem",
                    "&.Mui-checked": {
                      color: "#005f40",
                    },
                  }}
                />
              }
              sx={{ color: "#005f40" }}
              label={
                <label>
                  <a href="/terminos" target="_blank">
                    {t("Login.acceptedTerms")}
                  </a>
                </label>
              }
            />
            <Button
              type="submit"
              style={{ display: "none" }}
              ref={submitButtonRef}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};
