import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

type idGroupProps = {
  pending?: boolean;
  error?: boolean;
  id: any[];
};

const initialState: idGroupProps = {
  pending: false,
  error: false,
  id: [],
};

export const producerIdSlice = createSlice({
  name: "createProducerId",
  initialState,
  reducers: {
    producerId: (state, action) => {
      state.id = action.payload;
    },
  },
});
export const { producerId } = producerIdSlice.actions;
export const selectProducerId = (state: RootState) => state.createPoducerId;
export const createProducerIdReduce = producerIdSlice.reducer;
