import React, { useEffect, useState } from "react";
import Router, { useRouter } from "next/router";
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Alert,
  Modal,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google"; // Import Material UI Google icon
import { useAuth } from "../../context/auth-context";
import Image from "next/image";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { RegisterUserFirebase } from "./modal-register";
import { TermsAccepte } from "./modal-terms-accepted";


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "70%", sm: "60%", md: "40%", lg: "40%", xl: "30%" },
  heigth: { xs: "50%", sm: "50%", md: "50%", lg: "50%", xl: "40%" },
  bgcolor: "background.paper",
  border: "2px solid #FFF",
  boxShadow: 24,
  p: 3,
};

export default function LoginForm() {

  const {
    login,
    googleLogin,
    sendPasswordReset,
    error,
    registered,
    email,
    token,
    accepteTerms,
    userId,
    isBackdropOpen,
    isOpenRegister,

  } = useAuth() as any;

  const [emailUser, setEmailUser] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false) as any;
  const [openRegister, setOpenRegisterModal] = useState(false);
  const [openRegisterResetPassword, setOpenRegisterModalResetPassword] =
    useState(false) as any;
  const [showAlert, setShowAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [termsAcceptedOpen, setTermsAcceptedOpen] = useState(accepteTerms);

  const { t } = useTranslation();
  const router = useRouter();

  useEffect(() => {
    if (router.query.register) {
      setOpenRegisterModal(true);
    }
  }, [router.query.register]);

  useEffect(() => {
    if (isOpenRegister) {
      setOpenRegisterModal(true);
    }
  }, [isOpenRegister]);

  useEffect(() => {
    if (accepteTerms) {
      setTermsAcceptedOpen(true);
    } else {
      setTermsAcceptedOpen(false);
    }
  }, [accepteTerms]);

  const handleEmailChange = (e: any) => setEmailUser(e.target.value);
  const handlePasswordChange = (e: any) => setPassword(e.target.value);
  const handleOpen = () => setOpen(true);
  const handleCloseRegister = () => { setOpenRegisterModal(false); router.push('/', undefined, { shallow: true }); }
  const handleClosetermsAccepted = () => setTermsAcceptedOpen(false);
  const handleCloseRegisterResetPassword = () =>
    setOpenRegisterModalResetPassword(false);

  const handleOpenRegister = () => {
    setOpenRegisterModal(true);
    setOpen(false);
    router.push('/?register=true', undefined, { shallow: true });
  };
  const handleClose = () => setOpen(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await login(emailUser, password);
      router.push("/dashbord");
    } catch (error: any) { }
  };

  const handleGoogleLogin = async (e: any) => {
    e.preventDefault();
    try {
      await googleLogin();
      if (registered == true) {
        router.reload();
      }
    } catch (error: any) { }
  };

  const resetPassword = async () => {
    await sendPasswordReset(emailUser)
      .then(() => {
        setShowAlert(true);
      })
      .catch((error: any) => {
        setShowAlert(false);
        handleOpenRegister();
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "15%",
            minWidth: "100%",
          }}
        >
          <Image
            src="/logo.png"
            alt="logo fastfarm"
            width={200}
            height={200}
            objectFit="scale-down"
          />
        </Box>
        <form onSubmit={handleSubmit} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("Login.email")}
            value={emailUser}
            onChange={handleEmailChange}
            autoComplete="email"
            color="success"
            autoFocus
            inputProps={{
              style: { padding: "12px 9px", fontSize: "1rem" },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label={t("Login.password")}
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            color="success"
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={handleTogglePassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              style: { padding: "12px 9px", fontSize: "1rem" },
            }}
            sx={{ marginBottom: "3%" }}
          />
          <Box display="flex" flexDirection="row">
            <Box display="flex" width="50%" justifyContent="start">
              <Typography
                component="span"
                onClick={handleOpen}
                color="#005f40"
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                {t("Login.resetPassword")}
              </Typography>
            </Box>
            <Box display="flex" width="50%" justifyContent="end">
              <Typography
                component="span"
                onClick={handleOpenRegister}
                color="#005f40"
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                {t("Login.register")}
              </Typography>
            </Box>
          </Box>
          {error && <Alert severity="error">{error}</Alert>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 4,
              mb: 1,
              padding: "10px 0px",
              borderRadius: "25px",
              backgroundColor: "#005f40",
              color: "#EDEDED",
              fontSize: "1rem",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#005f40",
                color: "#EDEDED",
              },
            }}
          >
            {t("Login.button")}
          </Button>
        </form>

        <Button
          onClick={(e) => {
            handleGoogleLogin(e);
          }}
          fullWidth
          variant="outlined"
          sx={{
            mt: 2,
            mb: 1,
            padding: "8px 0px",
            borderRadius: "25px",
            justifyContent: "center",
            color: "#005f40",
            borderColor: "#005f40",
            textTransform: "none",
            fontSize: "1rem",
            "&:hover": {
              borderColor: "#EDEDED",
            },
          }}
          startIcon={<GoogleIcon />}
        >
          {t("Login.loginGoogle")}
        </Button>
      </Box>
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableEnforceFocus
        >
          <Box sx={style}>
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignContent="center"
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="resetEmail"
                label="Email"
                defaultValue={emailUser}
                onChange={handleEmailChange}
                autoComplete="email"
                color="success"
                autoFocus
              />
              <Box
                width="100%"
                display="flex"
                justifyContent="center"
                alignContent="center"
              >
                <Button
                  variant="contained"
                  onClick={resetPassword}
                  sx={{
                    width: "90%",
                    mt: 3,
                    mb: 2,
                    borderRadius: "25px",
                    backgroundColor: "#005f40",
                    color: "#EDEDED",
                    fontSize: "1rem",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#005f40",
                      color: "#EDEDED",
                    },
                  }}
                >
                  {t("Login.resetPassword")}
                </Button>
              </Box>
              {showAlert && (
                <Alert severity="success">
                  {t("Login.message.resetPasswordSucces")}
                </Alert>
              )}
              {!showAlert && (
                <RegisterUserFirebase
                  open={openRegisterResetPassword}
                  onClose={handleCloseRegisterResetPassword}
                  messageNotRegistered={!showAlert}
                  id="1"
                  emailRegister={emailUser}
                />
              )}
            </Box>
          </Box>
        </Modal>
      )}
      {openRegister && (
        <RegisterUserFirebase
          open={openRegister}
          onClose={handleCloseRegister}
          messageNotRegistered={false}
          id="2"
          emailRegister={emailUser}
        />
      )}
      {termsAcceptedOpen && (
        <TermsAccepte
          open={termsAcceptedOpen}
          onClose={handleClosetermsAccepted}
          email={emailUser}
          password={password}
          userId={userId}
        />
      )}
      <Backdrop open={isBackdropOpen} sx={{ zIndex: 9999 }}>
        <Alert sx={{ m: 2, color: "#005f40", display: "flex", alignItems: "center" }}>
          {t("Login.message.verifyEmail")}
          <CircularProgress size={20} sx={{ color: "#005f40", ml: 2 }} />
        </Alert>
      </Backdrop>
    </Container>

  );
}
