import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

export type createFileProps = {
  pending?: boolean;
  error?: boolean;
  geoJsonFile: {
    type: string;
    features: any[];
  };
};

const initialState: createFileProps = {
  pending: false,
  error: false,
  geoJsonFile: { type: "", features: [] },
};

export const fileSlice = createSlice({
  name: "createFile",
  initialState,
  reducers: {
    fileGeoJson: (state, action) => {
      state.geoJsonFile = action.payload;
    },
  },
});

export const { fileGeoJson } = fileSlice.actions;
export const selectFile = (state: RootState) => state.createFile;
export const fileReduce = fileSlice.reducer;
