import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

export type createGeoJsonProp = {
  pending?: boolean;
  error?: boolean;
  errorField: any[];
};

const initialState: createGeoJsonProp = {
  pending: false,
  error: false,
  errorField: [],
};

export const errorFieldSlice = createSlice({
  name: "createErrorField",
  initialState,
  reducers: {
    arrayErrorField: (state, action) => {
      state.errorField = action.payload;
    },
  },
});

export const { arrayErrorField } = errorFieldSlice.actions;
export const selectErrorField = (state: RootState) => state.createErrorField;
export const errorFieldReduce = errorFieldSlice.reducer;
